var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React, { Component } from 'react';
import $ from 'classnames';
import { observer } from 'mobx-react';
import styles from './styles.module.scss';
import toLower from 'lodash/toLower';
import { localize } from '@widgets/core';
import { each } from 'lodash';
var s = localize({
    close: ['close', 'cerrar'],
    notificaton: ['notification', 'notificación']
});
var Notification = /** @class */ (function (_super) {
    __extends(Notification, _super);
    function Notification() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.ref = React.createRef();
        _this.state = { isClosing: false };
        _this.close = function () {
            _this.setState({ isClosing: true });
            setTimeout(_this.props.onClose, 1000);
        };
        return _this;
    }
    Notification.prototype.addTracking = function () {
        each(document.querySelectorAll('.notification-message'), function (e) {
            each(e.querySelectorAll('a'), function (a) {
                a.setAttribute('data-cbtrack-linktype', 'promo');
            });
        });
    };
    Notification.prototype.componentDidMount = function () {
        this.addTracking();
    };
    Notification.prototype.componentDidUpdate = function () {
        this.addTracking();
    };
    Notification.prototype.render = function () {
        var _a;
        var notification = this.props.notification;
        var icon = { 'lv-success': 'success', 'lv-alert': 'warning' }[toLower(notification.type)] || 'danger';
        var iconType = { 'lv-success': 'Success', 'lv-alert': 'Alert' }[toLower(notification.type)] || 'Emergency';
        return (React.createElement("div", { key: notification.id, className: $(styles.root, (_a = {}, _a[styles.closing] = this.state.isClosing, _a)) },
            React.createElement("div", { className: $("alert alert-" + icon + " cb-alert-heading alert-dismissible", styles.alert) },
                React.createElement("div", { className: "container" },
                    React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "col-md-12 col-lg-12" },
                            React.createElement("button", { onClick: this.close, "aria-label": s.close + " " + s.notificaton, className: $('close', styles.closeButton), "data-dismiss": "alert" }),
                            React.createElement("h3", null, notification.title),
                            React.createElement("p", { className: "notification-message", dangerouslySetInnerHTML: { __html: notification.message } })))))));
    };
    Notification = __decorate([
        observer
    ], Notification);
    return Notification;
}(React.Component));
var default_1 = /** @class */ (function (_super) {
    __extends(default_1, _super);
    function default_1() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    default_1.prototype.render = function () {
        var _a = this.props, notifications = _a.notifications, closeNotification = _a.closeNotification, tracker = _a.tracker;
        if (notifications.length === 0)
            return null;
        return (React.createElement("div", { id: tracker.id, className: "cb-widget " + styles.widgetRoot, "data-cbtrack-widget": "notification" }, notifications.map(function (notification) {
            return React.createElement(Notification, { key: notification.id, notification: notification, onClose: function () { return closeNotification(notification.id); } });
        })));
    };
    default_1.prototype.componentDidMount = function () {
        this.props.tracker.mounted();
    };
    default_1.prototype.componentWillUnmount = function () {
        this.props.tracker.unmounted();
    };
    return default_1;
}(Component));
export default default_1;
