import { HeaderWidget } from '@widgets/header';
import { FooterWidget } from '@widgets/footer';
import { NotificationWidget } from '@widgets/notification';
import { CollegeSearchWidget } from '@widgets/search';
import StandaloneIdentityWidget, { PersonalizedIdentityWidget, HeroLoginWidget } from '@widgets/identity';
import { SiteSearchWidget } from '@widgets/sitesearch';
import loadable from '@loadable/component';
import { withStaticRender } from '@widgets/core';
var DynamicCollegeListWidget = withStaticRender(loadable(function () { return import('@widgets/college-list').then(function (d) { return d.CollegeListWidget; }); }));
export var BackwardCompatible = {
    HeaderWidget: HeaderWidget,
    FooterWidget: FooterWidget,
    NotificationWidget: NotificationWidget,
    CollegeSearchWidget: CollegeSearchWidget,
    HeroLoginWidget: HeroLoginWidget,
    SiteSearchWidget: SiteSearchWidget
};
export var Official = {
    GlobalHeader: HeaderWidget,
    GlobalFooter: FooterWidget,
    Notification: NotificationWidget,
    SiteSearch: SiteSearchWidget,
    Identity: StandaloneIdentityWidget,
    HeroIdentity: HeroLoginWidget,
    PersonalizedIdentity: PersonalizedIdentityWidget,
    CollegeSearch: CollegeSearchWidget,
    CollegeList: DynamicCollegeListWidget
};
