import React from 'react';
import NotificationWidgetInner from './NotificationWidget';
import { widget } from '@widgets/core';
import NotificationStore from './store';
export var NotificationWidget = widget('notification')(function () { return new NotificationStore; }, function (store, tracker) {
    if (store.config.useService && store.request.state === "pending")
        return null;
    return (React.createElement(NotificationWidgetInner, { notifications: store.notifications, closeNotification: store.closeNotification, tracker: tracker }));
});
export default NotificationWidget;
