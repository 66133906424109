var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React from 'react';
import $ from 'classnames';
import { observer } from 'mobx-react';
import SuggestionsList from '../SuggestionList';
import SearchWidgetContainer from '../SearchWidgetContainer';
import SearchInput from '../SearchInput';
import SearchIcon from '../SearchIcon';
import { URL } from '@widgets/core';
import styles from './collegesearchstyles.module.scss';
var searchURL = 'https://search.collegeboard.org/';
var SearchWidgetCore = /** @class */ (function (_super) {
    __extends(SearchWidgetCore, _super);
    function SearchWidgetCore() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.formRef = React.createRef();
        _this.searchInputRef = React.createRef();
        _this.suggestionsListRef = React.createRef();
        _this.currentSuggestion = null;
        return _this;
    }
    Object.defineProperty(SearchWidgetCore.prototype, "input", {
        get: function () {
            return this.searchInputRef.current.input;
        },
        enumerable: true,
        configurable: true
    });
    SearchWidgetCore.prototype.render = function () {
        var _this = this;
        var _a = this.props, sensitivity = _a.sensitivity, searchType = _a.searchType, siteType = _a.siteType, startValue = _a.startValue, limit = _a.limit, clearSuggestions = _a.clearSuggestions, suggestions = _a.suggestions, fetchSuggestions = _a.fetchSuggestions, tracker = _a.tracker;
        var suggestionsListId = '' + Date.now();
        console.log(this.props);
        var submitForm = function () {
            if (_this.searchInputRef.current.value.length > 0) {
                _this.formRef.current.submit();
            }
        };
        var redirectToSelection = function () {
            if (!_this.input.value)
                return;
            else if (_this.props.onSelect) {
                _this.props.onSelect(_this.currentSuggestion);
                clearSuggestions();
                _this.input.value = '';
            }
            else
                window.top.location.href = URL.withQueryParams('https://bigfuture.collegeboard.org/college-profile', {
                    id: String(_this.currentSuggestion.id),
                    q: _this.currentSuggestion.value,
                    searchtype: 'college'
                });
        };
        return (React.createElement("div", { id: tracker.id, "data-cbtrack-widget": "search", className: $("cb-widget", styles.root) },
            React.createElement("label", { className: styles.label }, "College Search"),
            React.createElement(SearchWidgetContainer, { className: styles.container, onBlur: clearSuggestions },
                React.createElement("form", { action: searchURL, method: "GET", ref: this.formRef, onSubmit: function (e) {
                        e.preventDefault();
                        submitForm();
                    } },
                    React.createElement("div", { className: styles.inner },
                        React.createElement("input", { type: "hidden", name: "tp", value: "usearch" }),
                        React.createElement("input", { type: "hidden", name: "searchType", value: "site_qfs" }),
                        React.createElement("input", { type: "hidden", name: "bf_cat", value: "college" }),
                        React.createElement("input", { type: "hidden", name: "siteType", value: siteType }),
                        React.createElement(SearchInput, { ref: this.searchInputRef, className: styles.input, startValue: this.props.startValue, sensitivity: sensitivity, suggestions: suggestions, placeholder: 'Search by college name', fetchSuggestions: fetchSuggestions, suggestionsList: this.suggestionsListRef, suggestionsListId: suggestionsListId }),
                        React.createElement(SearchIcon, { className: styles.link, onClick: function () {
                                clearSuggestions();
                                submitForm();
                            } }))),
                React.createElement(SuggestionsList, { id: suggestionsListId, ref: this.suggestionsListRef, theme: this.props.theme.suggestions || {}, limit: limit, suggestions: suggestions, onFocus: function (id) {
                        _this.input.setAttribute('ariaactivedescendent', id);
                    }, onSelection: function (suggestion) {
                        _this.currentSuggestion = suggestion;
                        _this.input.value = suggestion.value;
                        redirectToSelection();
                    }, onEscape: function () {
                        _this.input.focus();
                        clearSuggestions();
                    } }))));
    };
    SearchWidgetCore.prototype.componentDidMount = function () {
        this.props.tracker.mounted();
    };
    SearchWidgetCore.prototype.componentWillUnmount = function () {
        this.props.tracker.unmounted();
    };
    SearchWidgetCore.defaultProps = {
        theme: {}
    };
    SearchWidgetCore = __decorate([
        observer
    ], SearchWidgetCore);
    return SearchWidgetCore;
}(React.Component));
export default SearchWidgetCore;
