var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Component } from 'react';
import $ from 'classnames';
import styles from './styles.module.scss';
import logoClasses from './logoClasses';
var NavigationLinks = /** @class */ (function (_super) {
    __extends(NavigationLinks, _super);
    function NavigationLinks() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    NavigationLinks.prototype.render = function () {
        var props = this.props;
        return (React.createElement("div", { className: styles.root },
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "row" },
                    React.createElement(Category, { links: props.k12Links, offset: true, className: styles.k12 }),
                    React.createElement(Category, { links: props.accessLinks, className: styles.access }),
                    React.createElement("div", { className: "clearfix visible-sm-block" }),
                    React.createElement(Category, { links: props.higherEdLinks, offset: true, className: styles.higherEd }),
                    React.createElement(Category, { links: props.organizationLinks, className: styles.organization })))));
    };
    return NavigationLinks;
}(Component));
export default NavigationLinks;
var Category = function (_a) {
    var links = _a.links, _b = _a.offset, offset = _b === void 0 ? false : _b, className = _a.className;
    return (React.createElement("div", { role: "navigation", "aria-label": "Global Navigation", className: $('col-md-3 col-sm-5 col-md-offset-0', { 'col-sm-offset-1': offset }, styles.category) },
        React.createElement("div", { className: className },
            React.createElement("ul", { className: styles.outerList }, links.map(function (link) {
                return React.createElement(Link, __assign({ key: link.name }, link));
            })))));
};
var GlyphLink = function (_a) {
    var glyph = _a.glyph, url = _a.url, name = _a.name;
    return (React.createElement("li", { className: styles.glyphLink },
        React.createElement("a", { href: url, "aria-label": name, "data-cbtrack-linktype": "nav" },
            React.createElement("span", { className: "cb-glyph-xs cb-glyph-circular cb-icon-icn_" + glyph }))));
};
var Link = /** @class */ (function (_super) {
    __extends(Link, _super);
    function Link() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = { childLinkHovering: false, hovering: false };
        return _this;
    }
    Link.prototype.render = function () {
        var _a, _b;
        var _this = this;
        var _c = this.props, url = _c.url, logo = _c.logo, children = _c.children, name = _c.name, linkCode = _c.linkCode, type = _c.type;
        return (React.createElement("li", { className: $((_a = {}, _a[styles.hovering] = this.state.hovering, _a["visible-xs " + styles.mobile] = type === 'mobile', _a)) },
            React.createElement("a", { href: url, className: logo ? "cb-glyph-logo " + logoClasses[linkCode] + " " + styles.logo : styles.nonLogo, "aria-label": name, "data-cbtrack-linktype": "nav", onMouseEnter: function () { return _this.setState({ hovering: true }); }, onMouseLeave: function () { return _this.setState({ hovering: false }); } },
                React.createElement("span", { className: $({ 'sr-only': logo }) }, name)),
            !!children.length &&
                React.createElement("ul", { className: $((_b = {}, _b[styles.sublinks] = linkCode !== 'morecb', _b), styles[linkCode]) }, children.map(function (childLink) {
                    return childLink.glyph ?
                        React.createElement(GlyphLink, __assign({ key: childLink.name }, childLink)) :
                        React.createElement("li", { key: childLink.name, className: styles.textLink, onMouseEnter: function () { return _this.setState({ childLinkHovering: true }); }, onMouseLeave: function () { return _this.setState({ childLinkHovering: false }); } },
                            React.createElement("a", { href: childLink.url, "aria-label": childLink.name, "data-cbtrack-linktype": "nav" }, !childLink.glyph ?
                                React.createElement("span", null, childLink.name) :
                                React.createElement("span", { className: "cb-glyph-xs cb-glyph-circular cb-icon-icn_" + childLink.glyph })));
                }))));
    };
    return Link;
}(Component));
