var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import React from 'react';
import { levityStore as levity } from '@widgets/core';
import { observer } from 'mobx-react';
import $ from 'classnames';
import styles from './styles.module.scss';
import ghStyles from '../styles.module.scss';
import strings from '../l10n';
var LoginControl = /** @class */ (function (_super) {
    __extends(LoginControl, _super);
    function LoginControl() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.ref = React.createRef();
        return _this;
    }
    LoginControl.prototype.focus = function () {
        this.ref.current.focus();
    };
    LoginControl.prototype.render = function () {
        var _a;
        var onClick = this.props.onClick;
        return (React.createElement("a", { ref: this.ref, href: "#widget-login-control", "aria-label": strings.openLoginPanel, "aria-haspopup": "dialog", onClick: onClick, className: $(ghStyles.control, styles.root), "data-cbtrack-linktype": "toggle", "data-cbtrack-label": levity.isLoggedIn ? "[User First Name]" : null },
            React.createElement("span", { className: $(styles.name, "hidden-xs") }, levity.isLoggedIn ? levity.authSession.basicProfile.firstName : strings.signIn),
            React.createElement("span", { className: 'cb-glyph-multi cb-user cb-glyph-corporate3 cb-glyph-sm hidden-xs', "aria-hidden": "true" }),
            React.createElement("span", { className: $((_a = {},
                    _a['cb-glyph cb-signed-in visible-xs'] = levity.isLoggedIn,
                    _a['glyphicon-cb cb-icon-icn_signin visible-xs'] = !levity.isLoggedIn,
                    _a)), "aria-hidden": "true" })));
    };
    LoginControl = __decorate([
        observer
    ], LoginControl);
    return LoginControl;
}(React.Component));
export default LoginControl;
