export var Defaults = {
    skipLocation: '',
    fetchLinks: true,
    linksURL: 'https://mango.collegeboard.org/cbmango1/prod/api/all/1/globalheader-links.json',
    siteCode: 'gh',
    programType: 'corporate',
    appId: 8,
    useGlyphLogo: false,
    useGlobalNavigation: true,
    useLoginWidget: true,
    useSearchWidget: true
};
