var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Component } from 'react';
import $ from 'classnames';
import { localize, URL } from '@widgets/core';
import { observer } from 'mobx-react';
import { find, reject } from 'lodash';
var s = localize({
    signIn: ['Sign in to your account to get started', 'Inicia sesión en tu cuenta para comenzar'],
    dontHaveAccount: ["Don't have an account?", 'No tienes una cuenta?'],
    username: ['Username', 'Nombre de usuario'],
    password: ['Password', 'Contraseña'],
    createAccount: ['Create Account', 'Crear una Cuenta'],
    forgot: ['Forgot', 'Olvidaste'],
    rememberMe: ['Remember Me', 'Recuérdame'],
    submit: ['Submit', 'Enviar'],
    hello: ['Hello', 'Hola'],
    signOut: ['Sign Out', 'Desconectar'],
});
var setAttr = function (name, value) { return function (el) {
    if (el)
        el.setAttribute(name, value);
}; };
var RememberMeInput = /** @class */ (function (_super) {
    __extends(RememberMeInput, _super);
    function RememberMeInput(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            status: _this.props.rememberMe === '' ? false : true
        };
        return _this;
    }
    RememberMeInput.prototype.render = function () {
        var _this = this;
        var _a = this.props, id = _a.id, name = _a.name;
        var status = this.state.status;
        return (React.createElement("input", { type: "checkbox", id: id, name: name, defaultChecked: status, value: status === true ? "true" : "false", className: "cb-checkbox", onChange: function () {
                _this.setState({
                    status: !status
                });
            } }));
    };
    return RememberMeInput;
}(Component));
var StandaloneIdentityWidget = /** @class */ (function (_super) {
    __extends(StandaloneIdentityWidget, _super);
    function StandaloneIdentityWidget() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    StandaloneIdentityWidget.prototype.render = function () {
        var _a = this.props, allLinks = _a.allLinks, isLoggedIn = _a.isLoggedIn, userType = _a.userType, userName = _a.userName, logoutUrl = _a.logoutUrl, redirectUrl = _a.redirectUrl, signupUrl = _a.signupUrl, appId = _a.appId, tracker = _a.tracker;
        return (React.createElement("div", { id: tracker.id, "data-cbtrack-widget": "identity", className: "cb-widget" },
            !isLoggedIn
                ? React.createElement(ApricotSigninForm, __assign({}, this.props))
                : React.createElement(ApricotLinks, { links: allLinks, userType: userType, userName: userName, logoutUrl: logoutUrl, redirectUrl: redirectUrl, appId: appId }),
            !isLoggedIn &&
                React.createElement(CallToAction, { primary: true, href: signupUrl, label: s.createAccount, title: s.dontHaveAccount })));
    };
    StandaloneIdentityWidget.prototype.componentDidMount = function () {
        this.props.tracker.mounted();
    };
    StandaloneIdentityWidget.prototype.componentWillUnmount = function () {
        this.props.tracker.unmounted();
    };
    return StandaloneIdentityWidget;
}(Component));
var FullIdentityWidget = /** @class */ (function (_super) {
    __extends(FullIdentityWidget, _super);
    function FullIdentityWidget() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FullIdentityWidget.prototype.render = function () {
        var _a = this.props, allLinks = _a.allLinks, isLoggedIn = _a.isLoggedIn, userType = _a.userType, userName = _a.userName, logoutUrl = _a.logoutUrl, redirectUrl = _a.redirectUrl, signupUrl = _a.signupUrl, appId = _a.appId, tracker = _a.tracker;
        return (React.createElement("div", { id: tracker.id, "data-cbtrack-widget": "identity", className: "root cb-panel-gray" },
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "row" }, !isLoggedIn ? (React.createElement("div", { "data-cbtrack-linkgroup": "login" },
                    React.createElement("div", { className: "col-xs-12 col-sm-7 col-md-6 col-md-offset-1 col-lg-5 col-lg-offset-2" },
                        React.createElement(ApricotSigninForm, __assign({}, this.props))),
                    React.createElement("div", { className: "col-xs-12 col-sm-5 col-md-4" },
                        React.createElement(CallToAction, { primary: true, href: signupUrl, label: s.createAccount, title: s.dontHaveAccount })))) : (React.createElement("div", { className: "col-xs-12 col-sm-7 col-md-6 col-md-offset-1 col-lg-5 col-lg-offset-2" },
                    React.createElement(ApricotLinks, { links: allLinks, userType: userType, userName: userName, logoutUrl: logoutUrl, redirectUrl: redirectUrl, appId: appId })))))));
    };
    FullIdentityWidget.prototype.componentDidMount = function () {
        this.props.tracker.mounted();
    };
    FullIdentityWidget.prototype.componentWillUnmount = function () {
        this.props.tracker.unmounted();
    };
    return FullIdentityWidget;
}(Component));
export { FullIdentityWidget };
var HeroIdentityWidget = /** @class */ (function (_super) {
    __extends(HeroIdentityWidget, _super);
    function HeroIdentityWidget() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    HeroIdentityWidget.prototype.render = function () {
        var _a = this.props, allLinks = _a.allLinks, isLoggedIn = _a.isLoggedIn, userType = _a.userType, userName = _a.userName, logoutUrl = _a.logoutUrl, redirectUrl = _a.redirectUrl, signupUrl = _a.signupUrl, appId = _a.appId, tracker = _a.tracker;
        return (React.createElement(React.Fragment, null, !isLoggedIn ? (React.createElement("div", { "data-cbtrack-widget": "login" },
            React.createElement(ApricotSigninForm, __assign({}, this.props)),
            React.createElement(CallToAction, { href: signupUrl, label: s.createAccount, title: s.dontHaveAccount, noIcon: true }))) : (React.createElement(ApricotLinks, { links: allLinks, userType: userType, userName: userName, logoutUrl: logoutUrl, redirectUrl: redirectUrl, appId: appId }))));
    };
    HeroIdentityWidget.prototype.componentDidMount = function () {
        this.props.tracker.mounted();
    };
    HeroIdentityWidget.prototype.componentWillUnmount = function () {
        this.props.tracker.unmounted();
    };
    return HeroIdentityWidget;
}(Component));
export { HeroIdentityWidget };
function getEndpointParamArrayFromObject(endpointParams) {
    var params = [];
    for (var endpointKey in endpointParams) {
        params[endpointKey] = [];
        for (var paramKey in endpointParams[endpointKey]) {
            params[endpointKey].push({
                key: paramKey,
                value: endpointParams[endpointKey][paramKey]
            });
        }
    }
    return params;
}
function hiddenValues(endpointParams) {
    if (!endpointParams) {
        endpointParams = [];
    }
    return (React.createElement(React.Fragment, null, endpointParams.map(function (param) { return React.createElement("input", { type: "hidden", name: param.key, value: param.value }); })));
}
export var ApricotSigninForm = observer(function (_a) {
    var authenticationUrl = _a.authenticationUrl, redirectUrl = _a.redirectUrl, appId = _a.appId, forgotUsernameUrl = _a.forgotUsernameUrl, forgotPasswordUrl = _a.forgotPasswordUrl, rememberMe = _a.rememberMe, endpointParams = _a.endpointParams, hero = _a.hero;
    var params = getEndpointParamArrayFromObject(endpointParams);
    return (React.createElement("div", { className: "cb-login-module" },
        React.createElement("div", { className: "cb-login-module-header" },
            React.createElement("span", { className: "cb-glyph-multi cb-user", "aria-hidden": "true" }),
            React.createElement("h2", null, s.signIn)),
        React.createElement("div", { className: "cb-login-module-body" },
            React.createElement("form", { role: "form", action: authenticationUrl, method: "post" },
                React.createElement("input", { type: "hidden", name: "DURL", value: redirectUrl }),
                React.createElement("input", { type: "hidden", name: "appId", value: appId }),
                hiddenValues(params['authentication']),
                React.createElement("div", { className: "form-group" },
                    React.createElement("label", { htmlFor: "userName" }, s.username),
                    React.createElement("input", { type: "text", className: "form-control", id: "userName", name: "username", defaultValue: rememberMe })),
                React.createElement("div", { className: "form-group" },
                    React.createElement("label", { htmlFor: "password" }, s.password),
                    React.createElement("input", { type: "password", className: "form-control", id: "password", name: "password" })),
                React.createElement("div", { className: "checkbox" },
                    React.createElement("label", { className: "checkbox", htmlFor: "rememberMe" },
                        React.createElement(RememberMeInput, { id: "rememberMe", name: "rememberMe", rememberMe: rememberMe }),
                        React.createElement("span", { className: "cb-span" }),
                        s.rememberMe)),
                React.createElement("button", { type: "submit", className: $('btn btn-sm btn-primary cb-xs-btn-fluid', { 'cb-btn-fluid': hero }), "data-cbtrack-linktype": "nav" }, s.submit))),
        React.createElement(LoginControls, { forgotUsernameUrl: forgotUsernameUrl, forgotPasswordUrl: forgotPasswordUrl })));
});
export var CallToAction = function (props) { return (React.createElement("div", { className: "cb-cta-card cb-login-module-cta" },
    !props.noIcon &&
        React.createElement("span", { className: "cb-cta-card-icon cb-glyph-multi cb-add-user cb-glyph-corporate2", "aria-hidden": "true" }),
    React.createElement("h3", null, props.title),
    React.createElement("a", { href: props.href, className: "btn btn-sm btn-" + (props.primary ? 'primary' : 'secondary') + " cb-xs-btn-fluid", "data-cbtrack-linktype": "nav" }, props.label))); };
var ApricotLinks = observer(function (props) {
    var settingsLink = URL.withQueryParams(find(props.links, { name: 'Account Settings' }).url, {
        'appId': '' + props.appId,
        'DURL': props.redirectUrl
    });
    var links = reject(props.links, { name: 'Account Settings' });
    var linkGroup = props.userType === 'student' ? 'student-nav' : 'prof-nav';
    return (React.createElement("div", { className: "cb-login-module cb-logged-in", "data-cbtrack-linkgroup": linkGroup },
        React.createElement("div", { className: "cb-login-module-header" },
            React.createElement("span", { className: "cb-glyph cb-signed-in cb-glyph-circular", "aria-hidden": "true" }),
            React.createElement("h2", null,
                s.hello,
                ", ",
                props.userName,
                "!"),
            React.createElement(SignOut, { action: props.logoutUrl, appId: props.appId, redirectUrl: props.redirectUrl, className: "hidden-sm hidden-md hidden-lg" })),
        React.createElement("div", { className: "cb-login-module-body" },
            React.createElement("div", { className: "cb-item-list cb-item-list-simple" },
                React.createElement("ul", null, links.map(function (link) {
                    return React.createElement("li", { key: link.name },
                        React.createElement("a", { href: link.url, "data-cbtrack-linktype": "nav" },
                            React.createElement("div", { className: "cb-item-text" },
                                React.createElement("p", { className: "cb-item-title" }, link.name))));
                })))),
        React.createElement("div", { className: "cb-login-module-footer" },
            React.createElement("a", { href: settingsLink, "data-cbtrack-linktype": "nav" },
                React.createElement("span", { className: "cb-glyph cb-settings", "aria-hidden": "true" }),
                React.createElement("span", null, "Account Settings")),
            React.createElement(SignOut, { action: props.logoutUrl, appId: props.appId, redirectUrl: props.redirectUrl, className: "hidden-xs" }))));
});
var SignOut = function (props) { return (React.createElement("form", { acceptCharset: "utf-8", method: "post", action: props.action, className: props.className },
    React.createElement("input", { type: "hidden", name: "userType", value: "S" }),
    React.createElement("input", { type: "hidden", name: "DURL", value: props.redirectUrl }),
    React.createElement("input", { type: "hidden", name: "appId", value: props.appId }),
    React.createElement("input", { type: "hidden", name: "formState", value: "1" }),
    React.createElement("a", { href: props.redirectUrl, "data-cbtrack-linktype": "nav", onClick: function (e) {
            e.preventDefault();
            e.currentTarget.parentElement.submit();
        } },
        React.createElement("span", null, s.signOut)))); };
var LoginControls = function (props) {
    var username = React.createElement("a", { href: props.forgotUsernameUrl, "data-cbtrack-linktype": "nav" },
        React.createElement("span", { className: "sr-only" }, "Forgot "),
        " ",
        s.username);
    var password = React.createElement("a", { href: props.forgotPasswordUrl, "data-cbtrack-linktype": "nav" },
        React.createElement("span", { className: "sr-only" }, "Forgot "),
        " ",
        s.password);
    return (React.createElement("div", { className: "cb-login-module-footer" },
        React.createElement("p", null,
            s.forgot,
            " ",
            username,
            " or ",
            password,
            "?")));
};
export default StandaloneIdentityWidget;
