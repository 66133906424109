exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".cb-widget .RbKKTqRvEHJFklJwPUQfY{display:flex;align-items:center}@media only screen and (max-width: 768px){.cb-widget .RbKKTqRvEHJFklJwPUQfY{margin:10px 0 !important}}.cb-widget .lg6NbuIKODQEF5LBedaAC,.cb-widget .lg6NbuIKODQEF5LBedaAC:focus{flex-grow:1;text-shadow:none;box-shadow:none !important;border-radius:0;padding:3px 12px;display:inline}.cb-widget ._2twFIdtbmrr3-dKDiC9DMb{margin-top:0 !important}\n", ""]);

// exports
exports.locals = {
	"form": "RbKKTqRvEHJFklJwPUQfY",
	"input": "lg6NbuIKODQEF5LBedaAC",
	"button": "_2twFIdtbmrr3-dKDiC9DMb"
};