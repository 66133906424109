var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { Fragment } from 'react';
import $ from 'classnames';
import { GlobalHeaderSearch } from '@widgets/search';
import styles from './styles.module.scss';
import ghStyles from '../styles.module.scss';
import s from '../l10n';
var default_1 = /** @class */ (function (_super) {
    __extends(default_1, _super);
    function default_1() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.ref = React.createRef();
        return _this;
    }
    default_1.prototype.focus = function () {
        this.ref.current.focus();
    };
    default_1.prototype.render = function () {
        var _a = this.props, onClick = _a.onClick, searchConfig = _a.searchConfig;
        return (React.createElement(Fragment, null,
            React.createElement("div", { className: "hidden-xs " + styles.root },
                React.createElement(GlobalHeaderSearch, __assign({}, searchConfig))),
            React.createElement("a", { ref: this.ref, "data-cbtrack-linktype": "toggle", href: "#widget-control-search", "aria-label": s.openSearchPanel, "aria-haspopup": "dialog", onClick: onClick, className: $('hidden-md hidden-sm hidden-lg', ghStyles.control, styles.control) },
                React.createElement("span", { className: 'cb-glyph cb-search' }),
                React.createElement("span", { className: 'sr-only' }, s.openSearchPanel))));
    };
    return default_1;
}(React.Component));
export default default_1;
