var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import $ from 'classnames';
import { localize } from '@widgets/core';
var strings = localize({
    search: ['Search', 'Buscar'],
});
var SearchInput = /** @class */ (function (_super) {
    __extends(SearchInput, _super);
    function SearchInput() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.ref = React.createRef();
        _this.clearSuggestions = function () {
            _this.props.suggestions.clear();
            _this.ref.current.setAttribute('aria-activedescendant', '');
            _this.ref.current.setAttribute('aria-describedby', '');
        };
        _this.fetchSuggestionsOrClear = function () {
            var _a = _this.props, sensitivity = _a.sensitivity, fetchSuggestions = _a.fetchSuggestions;
            if (_this.value.length >= sensitivity) {
                fetchSuggestions(_this.value);
                _this.ref.current.setAttribute('aria-describedby', _this.props.suggestionsListId);
            }
            else
                _this.clearSuggestions();
        };
        _this.onKeyDown = function (e) {
            if (e.key === 'Tab')
                _this.clearSuggestions();
        };
        _this.onKeyUp = function (e) {
            if (e.key === 'Escape')
                _this.clearSuggestions();
            else if (e.key === 'ArrowDown') {
                if (_this.props.suggestions.length) {
                    var elm = _this.props.suggestionsList.current.focus();
                    _this.ref.current.setAttribute('aria-activedescendant', elm.getAttribute('id'));
                }
                else
                    _this.fetchSuggestionsOrClear();
            }
        };
        return _this;
    }
    Object.defineProperty(SearchInput.prototype, "input", {
        get: function () {
            return this.ref.current;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SearchInput.prototype, "value", {
        get: function () {
            return this.input.value.trim();
        },
        enumerable: true,
        configurable: true
    });
    SearchInput.prototype.render = function () {
        var a11yAttrs = {
            'aria-activedescendant': '',
            'aria-describedby': ''
        };
        var placeholder = this.props.placeholder || (strings.search);
        return (React.createElement("input", __assign({ name: "word", type: "text", ref: this.ref, autoComplete: "off", className: $('form-control', this.props.className) }, a11yAttrs, { "aria-haspopup": "listbox", "data-search": "search", placeholder: placeholder, title: placeholder, onKeyUp: this.onKeyUp, onKeyDown: this.onKeyDown, onChange: this.fetchSuggestionsOrClear })));
    };
    SearchInput.prototype.componentDidMount = function () {
        this.ref.current.value = this.props.startValue || '';
    };
    return SearchInput;
}(React.Component));
export default SearchInput;
