import React from 'react';
import { dpStore, widget, levityStore as levity } from '@widgets/core';
import StandaloneIdentityWidget, { FullIdentityWidget, HeroIdentityWidget } from './IdentityWidget';
import IdentityWidgetStore from './store';
export var PersonalizedIdentityWidget = widget('identity')(function () { return new IdentityWidgetStore; }, function (store, tracker) {
    return (React.createElement(FullIdentityWidget, { tracker: tracker, appId: dpStore.appId, allLinks: store.allLinks, isLoggedIn: levity.isLoggedIn, userType: levity.userType, userName: levity.isLoggedIn && levity.authSession.basicProfile.firstName, forgotUsernameUrl: store.endpoint('forgotUsername'), authenticationUrl: store.endpoint('authentication'), forgotPasswordUrl: store.endpoint('forgotPassword'), logoutUrl: store.endpoint('logout'), signupUrl: store.endpoint('signUp'), redirectUrl: store.redirectUrl, rememberMe: store.rememberMe, endpointParams: store.config.endpointParams }));
});
export var IdentityWidget = widget('identity')(function () { return new IdentityWidgetStore; }, function (store, tracker) {
    return (React.createElement(StandaloneIdentityWidget, { tracker: tracker, appId: dpStore.appId, allLinks: store.allLinks, isLoggedIn: levity.isLoggedIn, userType: levity.userType, userName: levity.isLoggedIn && levity.authSession.basicProfile.firstName, rememberMe: store.rememberMe, forgotUsernameUrl: store.endpoint('forgotUsername'), authenticationUrl: store.endpoint('authentication'), forgotPasswordUrl: store.endpoint('forgotPassword'), logoutUrl: store.endpoint('logout'), signupUrl: store.endpoint('signUp'), redirectUrl: store.redirectUrl, endpointParams: store.config.endpointParams }));
});
export default IdentityWidget;
//const bgImage = 'https://uihubstg.wcm.collegeboard.org/ui/3.4.0/images/login-hero-image-desktop.jpg'
var bgImage = 'https://www.collegeboard.org/sites/default/files/Standard-Hero_BH_2560x384-Default.jpg';
var Hero = function (_a) {
    var children = _a.children, program = _a.program, imageUrl = _a.imageUrl, headerText = _a.headerText, bodyText = _a.bodyText;
    return (React.createElement("div", { className: "cb-login-hero cb-" + program + "-program" },
        React.createElement("div", { className: "cb-login-img-container" },
            React.createElement("div", { className: "container" },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-xs-12 col-sm-6 col-md-offset-1 col-md-5 cb-login-img", style: { backgroundImage: "url(" + imageUrl + ")" } })))),
        React.createElement("div", { className: "cb-block-content container" },
            React.createElement("div", { className: "row" },
                React.createElement("div", { className: "col-xs-12 col-sm-6 col-md-offset-1 col-md-5 cb-hero-content" },
                    React.createElement("div", { className: "content" },
                        React.createElement("h2", { dangerouslySetInnerHTML: { __html: headerText } }),
                        React.createElement("p", { dangerouslySetInnerHTML: { __html: bodyText } }))),
                React.createElement("div", { className: "col-xs-12 col-sm-6 col-md-5 col-lg-4 col-lg-offset-1" },
                    React.createElement("div", { className: "content" }, children))))));
};
export var HeroLoginWidget = widget('identity')(function () { return new IdentityWidgetStore(); }, function (store, tracker) {
    return (React.createElement("div", { id: tracker.id, "data-cbtrack-widget": "identity" },
        React.createElement(Hero, { program: store.props.program, imageUrl: store.props.imageUrl, headerText: store.props.headerText, bodyText: store.props.bodyText },
            React.createElement(HeroIdentityWidget, { hero: true, tracker: tracker, appId: dpStore.appId, allLinks: store.allLinks, isLoggedIn: levity.isLoggedIn, userType: levity.userType, userName: levity.isLoggedIn && levity.authSession.basicProfile.firstName, rememberMe: store.rememberMe, forgotUsernameUrl: store.endpoint('forgotUsername'), authenticationUrl: store.endpoint('authentication'), forgotPasswordUrl: store.endpoint('forgotPassword'), logoutUrl: store.endpoint('logout'), signupUrl: store.endpoint('signUp'), redirectUrl: store.redirectUrl, endpointParams: store.config.endpointParams }))));
});
