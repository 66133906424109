var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import map from 'lodash/map';
var serialize = function (value) { return encodeURIComponent(value).replace(/%20/g, '+'); };
var deserialize = function (value) { return decodeURIComponent(value).replace(/\+/g, ' '); };
var URL = /** @class */ (function () {
    function URL(url) {
        this.anchor = null;
        this.queryParams = {};
        this.anchor = document.createElement('a');
        this.anchor.href = url;
        if (this.anchor.search) {
            var kvps = this.anchor.search.slice(1).split('&').map(function (keyVal) { return keyVal.split('='); });
            for (var _i = 0, kvps_1 = kvps; _i < kvps_1.length; _i++) {
                var _a = kvps_1[_i], name = _a[0], value = _a[1];
                this.queryParams[deserialize(name)] = deserialize(value);
            }
        }
    }
    URL.prototype.setQueryParam = function (name, value) {
        this.queryParams[name] = value;
        return this;
    };
    URL.prototype.setQueryParams = function (params) {
        this.queryParams = __assign(__assign({}, this.queryParams), params);
        return this;
    };
    URL.prototype.removeQueryParam = function (name) {
        delete this.queryParams[name];
    };
    Object.defineProperty(URL.prototype, "href", {
        get: function () {
            this.anchor.search = map(this.queryParams, function (value, key) { return serialize(key) + "=" + serialize(value); }).join('&');
            return this.anchor.href;
        },
        enumerable: true,
        configurable: true
    });
    URL.withQueryParams = function (url, params) {
        return new URL(url).setQueryParams(params).href;
    };
    return URL;
}());
export { URL };
